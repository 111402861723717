import React from "react";
import { ProductImage } from "./model/Products";

interface NameAndLink {
    name: string;
    link: string;
    image: string;
}

const ProductLink: React.FC<NameAndLink> = (nameAndLink: NameAndLink) => {
    return (
        <li>
            <p>
                <a href={nameAndLink.link}>
                    {nameAndLink.name}
                </a>
            </p>
            <a href={nameAndLink.link}>
                <img src={nameAndLink.image} alt="ecommerce" />
            </a>
        </li>
    )
};

const ProductPage: React.FC<ProductImage> = (productImage: ProductImage) => {
    return (
        <div>
            <p className="center">Click the link below to your favorite market place to order.</p>
            <ul>
                <ProductLink name="Lazada" link={productImage.links.lazada} image="/static/images/ecommerce-01-lazada.png" />
                <ProductLink name="Shopee" link={productImage.links.shopee} image="/static/images/ecommerce-02-shopee.png" />
                <ProductLink name="TikTok Shop" link={productImage.links.tiktok} image="/static/images/ecommerce-03-tiktok-shop.png" />
                <hr />
                <li>
                    <img className="product" src={productImage.image} alt={productImage.route} />
                </li>
            </ul>
        </div>
    )
};

export default ProductPage;